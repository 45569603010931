import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Qworpa is no more available.
        </p>
        <p>
          In case you want to use source code to have your own blog site feel free to use it.
        </p>
        <a
          className="App-link"
          href="https://github.com/koldakov/qworpa"
          target="_blank"
          rel="noopener noreferrer"
        >
          Qworpa Source code
        </a>
      </header>
    </div>
  );
}

export default App;
